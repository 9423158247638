import {
  Box,
} from "@mui/material";
import Modal from 'components/Common/Modal';

export default function UpdateAvatarModal({ onClose }) {
  const renderBody = () => {
    return (
      <Box sx={{ pt: 3 }}>
        Body
      </Box>
    )
  }

  return  (
    <Modal
      open={true}
      onClose={onClose}
      headerText="Update avatar"
      body={renderBody()}
    />
  )
}

import { ModalAction } from "constants/redux";

const openModalAction = (key, props) => ({
  type: ModalAction.OPEN_MODAL,
  data: { key, props },
});

const closeModalAction = (key) => ({
  type: ModalAction.CLOSE_MODAL,
  data: { key }
});

export { openModalAction, closeModalAction };

import { Box } from '@mui/material'
import React from 'react'
import { clsx } from 'clsx'
import './Container.css'

export default function PageContainer({ scrollEnabled, className, children, ...props }) {
  return (
    <Box className={clsx('PageContainerWrapper', scrollEnabled && 'PageContainer--scroll')}>
      <Box
        className={clsx("PageContainer", className)} {...props}>
        {children}
      </Box>
    </Box>
  )
}

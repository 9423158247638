import { Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Collapse,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
  Skeleton,
} from "@mui/material";

import LookupStatus, { LookupStatusSkeleton } from "components/Dashboard/Common/LookupStatus";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import ProvenanceEvent, { ProvenanceEventSkeleton } from "./ProvenanceEvent";
import { bcAddressRegex, secretIdRegex } from "constants/validation";
import LookupRepository from "repositories/lookup";
import { isEqual, pick } from "lodash";
import PageHeader from "components/Common/Page/Header";
import PageContainer from "components/Common/Page/Container";

const ProvenanceMatchingFields = [
  'agentBcAddress',
  'userBcAddress',
  'id',
  'encryptedMessage',
]

function ProvenanceResultSkeleton () {
  return (
    <Box mt={4} width='100%' maxWidth={976}>
      <LookupStatusSkeleton />
      <Box sx={{ mb: 6, display: 'flex', gap: 2 }}>
        <Box sx={{ flex: '1 1 0%' }}>
          <Box mb={1}>
            <Skeleton variant="text" height={20} width={100} />
          </Box>
          <ProvenanceEventSkeleton />
        </Box>
        <Box sx={{ flex: '1 1 0%' }}>
          <Box mb={1}>
            <Skeleton variant="text" height={20} width={90} />
          </Box>
          <ProvenanceEventSkeleton type="history" />
        </Box>
      </Box>
    </Box>
  )
}

export default function Provenance() {
  const [events, setEvents] = useState(null);
  const [eventFieldsError, setEventFieldsError] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      agentBcAddress: '',
      userBcAddress: '',
      secretId: '',
    },
    mode: 'all'
  });

  const [toast, setToast] = useState(null);

  const handleLookupIdentity = useCallback(async (data) => {
    const { agentBcAddress, userBcAddress, secretId } = data;
    setSubmitting(true);
    setEvents(null);

    try {
      const [rawBroadcastEvent] = await Promise.all([
        LookupRepository.getEventBroadcast(agentBcAddress, userBcAddress, secretId),
        // LookupRepository.getEventHistory(agentBcAddress, userBcAddress, secretId)
      ]);
      const broadcastEvent = {
        agentBcAddress: rawBroadcastEvent.decode.result.agentAddr,
        userBcAddress: rawBroadcastEvent.decode.result.userAddr,
        id: rawBroadcastEvent.decode.result.id,
        encryptedMessage: rawBroadcastEvent.decode.result.encMessage || '-',
        signature: '-',
      };
      const historyEvent = broadcastEvent; // TODO: fix later

      // set error
      const fieldsError = {};
      for (const field of ProvenanceMatchingFields) {
        const isFieldsEqual = isEqual(pick(broadcastEvent, [field]), pick(historyEvent, [field]));
        const isFieldEmpty = !pick(broadcastEvent, [field])[field];
        if (!isFieldsEqual || isFieldEmpty) {
          fieldsError[field] = true;
        }
      }
      if (!pick(historyEvent, ['signature'])['signature']) {
        fieldsError['signature'] = true;
      }
      setEventFieldsError(fieldsError);

      // set data
      setEvents({
        broadcastEvent,
        historyEvent
      });

    } catch (error) {
      setToast({
        severity: "error",
        description: error?.message || "Cannot lookup provenance."
      });
      setEvents(null);
    }
    setSubmitting(false);
  }, []);

  const isSubmitButtonDisabled = Object.values(errors).some(error => !!error) || submitting;

  const isNotFound = useMemo(() => (
    events !== null &&
    (!events.broadcastEvent || Object.keys(events.broadcastEvent).length === 0) &&
    (!events.historyEvent || Object.keys(events.historyEvent).length === 0)
  ), [events]);

  const areEventsMatched = useMemo(() => {
    if (events === null) {
      return false;
    }
    if (Object.values(eventFieldsError).some((error) => !!error)) {
      return false;
    }
    return true;
  }, [eventFieldsError, events]);

  const lookUpStatusProps = useMemo(() => {
    if (isNotFound) {
      return {
        status: false,
        title: 'Events not found',
        description: 'Not found any events.'
      }
    }
    if (!areEventsMatched) {
      return {
        status: false,
        title: 'Unmatched events',
        description: 'Exists unmatched information between two events.'
      }
    }
    return {
      status: true,
      title: 'Matched events',
      description: 'All information between two events is matched.'
    }
  }, [areEventsMatched, isNotFound])

  const renderForm = () => {
    return (
      <>
        <Box display="flex" flexDirection="column" sx={{ mt: 2, mb: 1 }}>
          <Box sx={{ mb: 1 }}>
            <Collapse in={toast !== null}>
              {toast && (
                <Alert severity={toast.severity} sx={{ mb: 1 }}>
                  {String(toast.description)}
                </Alert>
              )}
            </Collapse>
          </Box>
          <FormControl
            error={!!errors.agentBcAddress}
            sx={{ mb: 1, width: 480 }}
            variant="outlined"
            disabled={submitting}
          >
            <InputLabel htmlFor="outlined-agent-bc-address" size="small">
              Agent blockchain address
            </InputLabel>
            <OutlinedInput
              id="outlined-agent-bc-address"
              type={"text"}
              {...register('agentBcAddress', {
                validate: {
                  required: (value) => !!value || 'Agent blockchain address is required.',
                  bcAddress: (value) => !!value?.match(bcAddressRegex) || 'Invalid blockchain address.'
                }
              })}
              label="Agent blockchain address"
              size="small"
            />
            <FormHelperText id="outlined-adornment-agent-bc-address-text">
              {errors.agentBcAddress?.message ?? ''}
            </FormHelperText>
          </FormControl>
          <FormControl
            error={!!errors.userBcAddress}
            sx={{ mb: 1, width: 480 }}
            variant="outlined"
            disabled={submitting}
          >
            <InputLabel htmlFor="outlined-user-bc-address" size="small">
              User blockchain address
            </InputLabel>
            <OutlinedInput
              id="outlined-user-bc-address"
              type={"text"}
              {...register('userBcAddress', {
                validate: {
                  required: (value) => !!value || 'User blockchain address is required.',
                  bcAddress: (value) => !!value?.match(bcAddressRegex) || 'Invalid blockchain address.'
                }
              })}
              label="User blockchain address"
              size="small"
            />
            <FormHelperText id="outlined-adornment-user-bc-address-text">
              {errors.userBcAddress?.message ?? ''}
            </FormHelperText>
          </FormControl>
          <FormControl
            error={!!errors.secretId}
            sx={{ mb: 1, width: 480 }}
            variant="outlined"
            disabled={submitting}
          >
            <InputLabel htmlFor="outlined-secret-id-address" size="small">
              Secret Id
            </InputLabel>
            <OutlinedInput
              id="outlined-secret-id"
              type={"text"}
              {...register('secretId', {
                validate: {
                  required: (value) => !!value || 'Secret id is required.',
                  bcAddress: (value) => !!value?.match(secretIdRegex) || 'Invalid secret id.'
                }
              })}
              label="Secret Id"
              size="small"
            />
            <FormHelperText id="outlined-adornment-secret-id-text">
              {errors.secretId?.message ?? ''}
            </FormHelperText>
          </FormControl>
        </Box>
        <LoadingButton
          variant="contained"
          size="medium"
          onClick={handleSubmit(handleLookupIdentity)}
          disabled={isSubmitButtonDisabled}
          loading={submitting}
          endIcon={<Search />}
        >
          Lookup Provenance
        </LoadingButton>
      </>
    )
  }

  const renderResult = () => {
    return (
      <Box
        display='flex'
        flexDirection="column"
        alignItems='center'
        mt={4}
      >
        <Divider flexItem/>
        {submitting && (<ProvenanceResultSkeleton />)}
        {!submitting && events !== null && (
          <Box mt={4}>
            <LookupStatus {...lookUpStatusProps} />
            <Box sx={{ flex: 1, mb: 6, display: 'flex', gap: 2 }}>
              {!isNotFound && (
                <>
                  <Box sx={{ flex: '1 1 0%' }} width="100%" maxWidth={480}>
                    <Typography variant="body2" fontWeight="medium" sx={{ mb: 2 }}>
                      Broadcast event
                    </Typography>
                    <ProvenanceEvent
                      event={events.broadcastEvent}
                      error={eventFieldsError}
                    />
                  </Box>
                  <Box sx={{ flex: '1 1 0%' }} width="100%" maxWidth={480}>
                    <Typography variant="body2" fontWeight="medium" sx={{ mb: 2 }}>
                      History event
                    </Typography>
                    <ProvenanceEvent
                      type="history"
                      event={events.historyEvent}
                      error={eventFieldsError}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <>
      <PageContainer>
        <PageHeader title="Provenance" />
      </PageContainer>

      <PageContainer scrollEnabled>
        <Typography variant="body1">
          Lookup transaction provenances directly right in Blockchain Explorer.
        </Typography>
        {renderForm()}
        {renderResult()}
      </PageContainer>
    </>
  );
}

import { mainBaseUrl } from "configs";
import { getWithToken, postWithToken } from "utils/rest";

class LookupRepository {
  static async getIdentity(bcAddress) {
    const url = new URL(`${mainBaseUrl}/identity/getIdentity`);
    url.searchParams.append("bcAddr", bcAddress);

    try {
      const result = await getWithToken(url.toString());
      return JSON.parse(result);
    } catch (error) {
      throw error;
    }
  }

  static async getEventBroadcast(agentAddress, userAddress, secretId) {
    const url = `${mainBaseUrl}/provenance/eventBroadcast`;
    const body = {
      agentAddr: agentAddress,
      userAddr: userAddress,
      id: secretId,
    }
    try {
      const result = await postWithToken(url, body);
      return result;
    } catch (error) {
      throw error;
    }
  }

  static async getEventHistory(agentAddress, userAddress, secretId) {
    const url = `${mainBaseUrl}/provenance/eventHistory`;
    const body = {
      agentAddr: agentAddress,
      userAddr: userAddress,
      id: secretId,
    }
    try {
      const result = await postWithToken(url, body);
      return result;
    } catch (error) {
      throw error;
    }
  }
}

export default LookupRepository;

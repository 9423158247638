import { StatisticAction } from "constants/redux";

const initialState = {
  general: null,
  daily: null,
};

const statisticReducer = (state = initialState, action) => {
  switch (action.type) {
    case StatisticAction.GET_GENERAL_STATISITC_SUCCESS: {
      const { totalOTP, succeedOTP, failedOTP, totalTransaction, totalUsers } = action.data;
      const general = { totalOTP, succeedOTP, failedOTP, totalTransaction, totalUsers };

      return {
        ...state,
        general,
      };
    }
    case StatisticAction.GET_GENERAL_STATISITC_FAILED: {
      return { ...state };
    }
    case StatisticAction.GET_DAILY_STATISTIC_SUCCESS: {
      const daily = action.data;
      return {
        ...state,
        daily,
      };
    }
    case StatisticAction.GET_DAILY_STATISTIC_FAILED: {
      return { ...state };
    }
    default:
      return state;
  }
};

export default statisticReducer;

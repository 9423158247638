import { Box, Modal as BaseModal, Typography, IconButton } from "@mui/material";
import { Close } from '@mui/icons-material';

const modalBodyStyle = {
  margin: 'auto',
  marginTop: 24,
  width: '600px',
  borderRadius: 2,
  py: 3,
  bgcolor: 'background.paper',
  boxShadow: 24,
}

export default function Modal({ sx, children, headerText, body, footer, onClose, ...props }) {
  const renderHeader = () => {
    return (
      <Box sx={{ position: 'relative' }}>
        <Typography variant="h5" component="h2" sx={{ fontWeight: 500, px: 3 }}>
          Update avatar
        </Typography>
        <IconButton sx={{ position: 'absolute', top: -5, right: 12 }}>
          <Close size="small" onClick={onClose} />
        </IconButton>
      </Box>
    );
  }

  const renderBody = () => {
    return (
      <Box sx={{ px: 3 }}>
        {body}
      </Box>
    );
  }

  const renderFooter = () => {
    return (footer);
  }

  return (
    <BaseModal sx={{ ...sx }} {...props}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <Box sx={modalBodyStyle}>
          {renderHeader()}
          {renderBody()}
          {renderFooter()}
        </Box>
      </Box>
    </BaseModal>
  )
}

import { Box, Skeleton, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { useMemo } from 'react';

export function IdentityResultSkeleton (props) {
  const items = useMemo(() => Array.from({ length: 5 }), []);
  return (
    <Box {...props}>
      {items.map((_, index) => (
        <Box mb={1}>
          <Skeleton
            key={index}
            height={40}
            width="100%"
            variant="text"
            animation="wave"
          />
        </Box>
      ))}
    </Box>
  );
};


export default function IdentityResult ({
  userData,
}) {
  const { fullName, address, age, gender, debitor } = userData;

  return (
    <Box>
      <FormControl
        sx={{ mb: 1, width: "100%" }}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-fullname-address" size="small">
          Fullname
        </InputLabel>
        <OutlinedInput
          id="outlined-fullname-address"
          type={"text"}
          value={fullName ?? '-'}
          label="Fullname"
          size="small"
          readOnly
        />
      </FormControl>
      <FormControl
        sx={{ mb: 1, width: "100%" }}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-age-address" size="small">
          Age
        </InputLabel>
        <OutlinedInput
          id="outlined-age"
          type={"text"}
          value={age ?? '-'}
          label="Age"
          size="small"
          readOnly
        />
      </FormControl>
      <FormControl
        sx={{ mb: 1, width: "100%" }}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-gender-address" size="small">
          Gender
        </InputLabel>
        <OutlinedInput
          id="outlined-gender"
          type={"text"}
          value={gender ?? '-'}
          label="Gender"
          size="small"
          readOnly
        />
      </FormControl>
      <FormControl
        sx={{ mb: 1, width: "100%" }}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-debitor-address" size="small">
          Phone number
        </InputLabel>
        <OutlinedInput
          id="outlined-debitor"
          type={"text"}
          value={debitor ?? '-'}
          label="Phone number"
          size="small"
          readOnly
        />
      </FormControl>
      <FormControl
        sx={{ mb: 1, width: "100%" }}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-address-address" size="small">
          Address
        </InputLabel>
        <OutlinedInput
          id="outlined-address-address"
          type={"text"}
          value={address ?? '-'}
          label="Address"
          size="small"
          readOnly
        />
      </FormControl>
    </Box>
  )
}

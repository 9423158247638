import { Avatar as MUIAvatar, Box, CircularProgress, Typography } from "@mui/material";
import CameraAlt from "@mui/icons-material/CameraAlt";
import "./index.css";

function SquareAvatar({
  onClick,
  name,
  size,
  url,
  avatarSx,
  updatable = false,
  isUploading = false,
  variant = "square",
}) {
  const renderAction = () => {
    if (isUploading) {
      return (
        <div className="squareAvatarLoading">
          <CircularProgress sx={{ color: "#ffffff" }} />
        </div>
      )
    }

    if (updatable) {
      return (
        <div className="squareAvatarHover">
          <CameraAlt sx={{ color: "#ffffff" }} />
          <Typography
            variant="body2"
            component="div"
            sx={{ mt: 1, color: "#ffffff" }}
          >
            Choose Image
          </Typography>
        </div>
      )
    }

    return null;
  }

  const renderAvatar = () => {
    if (url) {
      return (
        <MUIAvatar
          variant={variant}
          src={url} // Display image from blob
          alt={name}
          sx={{ height: size, width: size, ...avatarSx }}
        />
      );
    }

    return (
      <MUIAvatar
        variant={variant}
        sx={{ height: size, width: size, ...avatarSx }}
      >
        {name.split(" ")[0][0].toUpperCase()}
      </MUIAvatar>
    )
  }

  return (
    <Box
      onClick={onClick}
      sx={{
        height: size,
        width: size,
        cursor: onClick ? "pointer" : "default",
      }}
      className="squareAvatar"
    >
      {renderAction()}
      {renderAvatar()}
    </Box>
  );
}

export default SquareAvatar;

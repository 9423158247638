import { Card, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function GeneralDataCard ({
  name, value, sx, isLoading
}) {
  const renderData = () => {
    if (isLoading) {
      return (
        <Skeleton
          width={120}
          height={56}
          variant="rounded"
          animation="wave"
        />
      )
    }

    return (
      <Typography
        variant="h3"
        component="div"
        sx={{ fontWeight: "bold" }}
        mr={2}
      >
        {new Intl.NumberFormat().format(value)}
      </Typography>
    );
  }

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 2,
        p: 3,
        flex: 1,
        ...sx
      }}
    >
      <Typography
        fontSize={16}
        fontWeight="medium"
        color="text.secondary"
      >
        {name}
      </Typography>

      <Box sx={{ mt: 1 }}>
        {renderData()}
      </Box>
    </Card>
  );
}

import { Chart } from 'chart.js/auto';
import { LinearScale } from 'chart.js';
import { Line } from "react-chartjs-2";
import { useMemo } from 'react';
import dayjs from 'dayjs';

Chart.register(
  LinearScale,
);

export default function TransactionChart({
  data,
  ...props
}) {

  const chartData = useMemo(() => ({
    labels: data?.map(({ day }) => dayjs(day).format('MMM DD')) ?? [],
    datasets: [
      {
        label: "Transaction",
        data: data?.map(({ value }) => value) ?? [],
        borderColor: '#378ab1',
        borderWidth: 2,
        fill: true,
        backgroundColor: 'rgba(55, 138, 177, 0.2)',
        pointBackgroundColor: '#ffffff',
      }
    ]
  }), [data]);

  if (!data) {
    return null;
  }

  return (
    <Line
      data={chartData}
      options={{
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
          tooltip: {
            titleMarginBottom: 8,
            padding: 16,
            backgroundColor: '#FFFFFF',
            borderWidth: 1,
            titleColor: '#000000',
            bodyColor: '#000000',
            borderColor: '#D1D5DB',
            displayColors: false,
            titleFont: {
              size: 14,
            },
            bodyFont: {
              size: 14,
            },
          },
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            ticks: {
              font: {
                size: 14,
              },
              padding: 0,
            },
            grid: {
              drawOnChartArea: false,
              tickWidth: 0,
              tickLength: 12,
            }
          },
          y: {
            ticks: {
              stepSize: 1,
              font: {
                size: 14,
              },
            },
            grid: {
              tickWidth: 0,
              tickLength: 12,
            },
            border: {
              display: false,
            },
            beginAtZero: true,
            suggestedMin: 0,
          },
        },
        maintainAspectRatio: false,
      }}
      {...props}
    />
  )
}

import React from 'react'
import { Typography } from '@mui/material'

export default function PageHeader({ title }) {
  return (
    <Typography variant="h4" sx={{ mt: 4, mb: 2, fontWeight: 500, }}>
      {title}
    </Typography>
  )
}

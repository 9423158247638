import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfoAction } from "actions/user";
import Navbar from "components/Common/Navbar";
import Sidebar from "components/Common/Sidebar";
import { Route, Routes, Navigate } from "react-router-dom";
import Analyser from "./Analyser";
import Identity from "./Identity";
import Provenance from "./Provenance";
import Profile from "./Profile";
import PageLayout from "components/Common/Page/Layout";
import { useCallback, useEffect, useState } from "react";

const drawerWidth = 240;

function Dashboard() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const dispatchGetUserInfo = useCallback(() => dispatch(getUserInfoAction()), [dispatch]);
  const sectionActive = useSelector((state) => state.user.sectionActive);

  const [isGettingInfo, setIsGettingInfo] = useState(true);

  const getUserInfo = useCallback(async () => {
    setIsGettingInfo(true);
    const result = await dispatchGetUserInfo();
    if (!result.success) {
      navigate('/auth/signin')
    }
    setIsGettingInfo(false);
  }, [dispatchGetUserInfo, navigate]);

  useEffect(() => {
    if (!sectionActive) {
      getUserInfo();
    }
  }, [sectionActive, getUserInfo]);

  if (isGettingInfo) {
    return (
      <Box className="min-h-screen w-full flex items-center justify-center">
        <CircularProgress />
      </Box>
    );
  }


  return (
    <Box display="flex" flexDirection="column" height="100vh">
      {/* <CssBaseline /> */}
      <Navbar drawerWidth={drawerWidth} />
      <Sidebar drawerWidth={drawerWidth} />
      {/* "Main content" */}
      <PageLayout
        flexGrow={1}
        mt={8}
        ml={`${drawerWidth + 47}px`}
      >
        <Routes>
          <Route path="/" element={<Analyser />}></Route>
          <Route path="/identity" element={<Identity />}></Route>
          <Route path="/provenance" element={<Provenance />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/*" element={<Navigate to="/" replace />}></Route>
        </Routes>
      </PageLayout>
    </Box>
  );
}

export default Dashboard;

import { combineReducers } from "redux";
import generalReducer from "./general";
import identityReducer from "./identity";
import modalReducer from "./modal";
import provenanceReducer from "./provenance";
import userReducer from "./user";
import statisticReducer from "./statistic";

const rootReducer = combineReducers({
  user: userReducer,
  statistic: statisticReducer,
  identity: identityReducer,
  provenance: provenanceReducer,
  general: generalReducer,
  modal: modalReducer,
});

export default rootReducer;

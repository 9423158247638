import { Box, Skeleton, Typography } from "@mui/material";
import { statusFailedImg, statusSuccessImg } from "assets/images";

export function LookupStatusSkeleton({ title = true, description = true }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mb: 4,
      }}
    >
      <Skeleton variant="circular" width={60} height={60} />
      {title && <Skeleton variant="text" width={120} height={32} sx={{ mt: 2, mb: 1 }} />}
      {description && <Skeleton variant="text" width={200} height={24} />}
    </Box>
  );
}

export default function LookupStatus ({ status, title, description }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mb: 4,
      }}
    >
      <Box sx={{ mb: 2 }}>
        <img
          src={status ? statusSuccessImg : statusFailedImg}
          alt="success"
          width="60"
          height="60"
        />
      </Box>
      <Typography
        variant="h5"
        sx={{
          mb: 1,
          fontWeight: "medium",
          color: (theme) => (status ? theme.palette.success.main : theme.palette.error.main),
        }}
      >
        {title}
      </Typography>
      <Typography variant="body1">
        {description}
      </Typography>
    </Box>
  );
}

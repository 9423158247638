import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { closeModalAction } from "actions/modal";

import { ModalComponent } from './constants';

export default function ModalContainer() {
  const dispatch = useDispatch();
  const modalData = useSelector((state) => state.modal);

  const onCloseModal = useCallback(() => dispatch(closeModalAction(modalData.key)), [dispatch, modalData.key])

  if (!modalData.isShowing || !modalData.key) {
    return null;
  }

  const Component = ModalComponent[modalData.key];

  return (
    <Component
      {...modalData.props }
      onClose={onCloseModal}
    />
  );
}

const appUrlProd = process.env.REACT_APP_APP_URL;
const mainBaseUrlProd = process.env.REACT_APP_MAIN_END_POINT;
const cloudinaryCloudNameProd = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
const cloudinaryUploadPresetProd =
  process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
const cloudinaryApiKeyProd = process.env.REACT_APP_CLOUDINARY_API_KEY;
const cloudinaryApiSecretProd = process.env.REACT_APP_CLOUDINARY_API_SECRET;
const docsUrlProd = process.env.REACT_APP_DOCS_URL;
const explorerUrlProd = process.env.REACT_APP_EXPLORER_URL;
export {
  appUrlProd,
  mainBaseUrlProd,
  cloudinaryCloudNameProd,
  cloudinaryUploadPresetProd,
  cloudinaryApiKeyProd,
  cloudinaryApiSecretProd,
  docsUrlProd,
  explorerUrlProd
};

import { GeneralAction } from "constants/redux";

const setIndetermineProgressBarAction = (active) => ({
  type: GeneralAction.SET_INDETERMINE_PROGRESS_BAR,
  data: { active }
});

export {
  setIndetermineProgressBarAction,
};

import { StorageKeyPrefix } from 'constants/storage';

class Storage {
  prefix;

  constructor(prefix) {
    this.prefix = prefix;
  }

  getFullKey = (key) => `${this.prefix}.${key}`;

  getItem(key) {
    return window.localStorage.getItem(this.getFullKey(key));
  }

  setItem(key, value) {
    window.localStorage.setItem(this.getFullKey(key), value);
  }

  removeItem(key) {
    window.localStorage.removeItem(this.getFullKey(key));
  }

  getJSON(key, initialValue) {
    try {
      const value = this.getItem(key);
      return value ? JSON.parse(value) : initialValue || null;
    } catch (e) {
      // Do nothing
      return initialValue || null;
    }
  }

  setJSON(key, value) {
    try {
      this.setItem(key, JSON.stringify(value));
    } catch (e) {
      // Do nothing
    }
  }
}

const storage = new Storage(StorageKeyPrefix);
export default storage;

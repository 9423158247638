import { mainBaseUrl } from "configs";
import { getWithToken, postWithToken } from "utils/rest";

class StatisticRepository {
  static async getGeneralStatistic() {
    const url = `${mainBaseUrl}/dashboardAuthen/analyser`;
    try {
      const result = await getWithToken(url);
      return result;
    } catch (error) {
      throw error;
    }
  }

  static async getDailyStatistic(agentAddress, timeRanges) {
    const url = `${mainBaseUrl}/dashboardAuthen/analyserDaily`;
    const body = { agentAddr: agentAddress, timeRanges: timeRanges };
    try {
      const result = await postWithToken(url, body);
      return result;
    } catch (error) {
      throw error;
    }
  }
}

export default StatisticRepository;

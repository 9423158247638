import { ModalAction } from "constants/redux";

const initialState = {
  isShowing: false,
  key: null,
  props: null,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ModalAction.OPEN_MODAL: {
      const { key, props } = action.data;
      return { isShowing: true, key, props };
    }
    case ModalAction.CLOSE_MODAL: {
      const { key } = action.data;
      if (!!key && state.key !== key) {
        return state;
      }
      return { isShowing: false, key: null, props: null };
    }
    default: {
      return state;
    }
  }
};

export default modalReducer;

import dayjs from 'dayjs';

export function getLastDateRanges(days) {
  const ranges = [];
  for (let i = days - 1; i >= 0; i-= 1) {
    const startOfDay = dayjs().subtract(i, 'day').startOf('day').valueOf();
    const endOfDay = dayjs().subtract(i, 'day').endOf('day').valueOf();
    ranges.push([startOfDay, endOfDay]);
  }
  return ranges;
}


// const renderTabLabel = (tab, value) => {
//   return (
//     <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 240, maxWidth: 240 }}>
//       <Typography
//         sx={{
//           textAlign: 'left',
//           textTransform: 'none',
//           fontWeight: 500
//         }}
//       >
//         {AnalyserTabName[tab]}
//       </Typography>

//       <Box>
//         {!Number.isInteger(value) ? (
//           <Skeleton
//             variant="text"
//             width={120}
//             height={56}
//             animation="wave"
//           />
//         ) : (
//           <Typography
//             variant="h3"
//             component="div"
//             sx={{
//               fontWeight: "bold",
//               textAlign: 'left',
//             }}
//           >
//             {new Intl.NumberFormat().format(value)}
//           </Typography>
//         )}
//       </Box>
//     </Box>
//   );
// }

// const renderTabs = () => {
//   return (
//     <Tabs
//       value={currentTab}
//       onChange={(_, tab) => {
//         setCurrentTab(tab)
//       }}
//     >
//       {[
//         {
//           tab: AnalyserTab.USER,
//           value: general?.totalUsers,
//         },
//         {
//           tab: AnalyserTab.TRANSACTION,
//           value: general?.totalTransaction,
//         }
//       ].map(({ tab, value }) => (
//         <Tab
//           key={tab}
//           label={renderTabLabel(tab, value)}
//           value={tab}
//         />
//       ))}
//     </Tabs>
//   );
// }

// const renderTransactionChart = () => {
//   return (
//     <Box sx={{ mt: 4 }}>
//       <Card
//         variant="outlined"
//         sx={{
//           p: 3,
//           borderRadius: 2,
//         }}
//       >
//         <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
//           Transactions
//         </Typography>
//         <TransactionChart data={dailyChartData} />
//       </Card>
//     </Box>
//   );
// }

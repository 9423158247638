const appUrlDev = process.env.REACT_APP_APP_URL;
const mainBaseUrlDev = process.env.REACT_APP_MAIN_END_POINT;
const cloudinaryCloudNameDev = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
const cloudinaryUploadPresetDev =
  process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
const cloudinaryApiKeyDev = process.env.REACT_APP_CLOUDINARY_API_KEY;
const cloudinaryApiSecretDev = process.env.REACT_APP_CLOUDINARY_API_SECRET;
const explorerUrlDev = process.env.REACT_APP_EXPLORER_URL;
const docsUrlDev = process.env.REACT_APP_DOCS_URL;
export {
  appUrlDev,
  mainBaseUrlDev,
  cloudinaryCloudNameDev,
  cloudinaryUploadPresetDev,
  cloudinaryApiKeyDev,
  cloudinaryApiSecretDev,
  docsUrlDev,
  explorerUrlDev
};

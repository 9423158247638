import axios from "axios";
import CommonErrors from "constants/error";
import { mainBaseUrl } from "configs";
import storage from 'utils/storage';
import { StorageKey } from "constants/storage";

const defaultHeaders = {
  "Content-Type": "application/json",
}

// Utils
const _getBearerConfig = () => {
  const config = {};
  const headers = { ...defaultHeaders };
  const origin = new URL(mainBaseUrl).origin;
  headers['Allow-Control-Allow-Origin'] = origin;

  const token = storage.getJSON(StorageKey.ACCESS_TOKEN);

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  config["headers"] = headers;
  return config;
};

const _queryRest = async (method, ...args) => {
  try {
    // Non-formating response
    const result = await axios[method](...args);
    return result.data;
  } catch (error) {
    // Get error conditionally
    throw String(error.message ?? "").includes(CommonErrors.NETWORK_ERROR)
      ? error
      : error.response.data;
  }
};

// Without token
const getWithoutToken = async (url, config) => _queryRest('get', url, config);
const postWithoutToken = async (url, data, config) => _queryRest('post', url, data, config);

// With bearer token
const getWithToken = async (url, config) => _queryRest('get', url, { ...config, ...(_getBearerConfig()) });
const postWithToken = async (url, data, config) => _queryRest('post', url, data, { ...config, ...(_getBearerConfig()) });

// unwrap dispatch request
export const unwrapRequest = async (request) => {
  const result = await request();
  if (!result.success) {
    throw result.error;
  }
}

export { getWithoutToken, postWithoutToken, getWithToken, postWithToken };

import { Box } from '@mui/material';

import GeneralDataCard from './GeneralDataCard';

export default function GeneralStatistic({
  data,
  isLoading,
  sx,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        ...sx
      }}
    >
      <GeneralDataCard
        name="Users"
        value={data?.totalUsers ?? 0}
        isLoading={isLoading}
      />
      <GeneralDataCard
        name="Transactions"
        value={data?.totalTransaction ?? 0}
        isLoading={isLoading}
      />
    </Box>
  )
}

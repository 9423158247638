import { Launch } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Skeleton, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useMemo } from "react";

export function ProvenanceEventSkeleton ({ type = 'broadcast', ...props}) {
  const items = useMemo(() => Array.from({ length: type === 'broadcast' ? 4 : 5 }), [type]);
  return (
    <Box {...props}>
      {items.map((_, index) => (
        <Box mb={1}>
          <Skeleton
            key={_}
            height={40}
            width="100%"
            variant="text"
            animation="wave"
          />
        </Box>
      ))}
      <Skeleton variant="rectangular" width={258} height={30} />
    </Box>
  )
}

export default function ProvenanceEvent({
  event,
  error,
  type = 'broadcast',
  ...props
}) {
  const {
    agentBcAddress,
    userBcAddress,
    id,
    encryptedMessage,
    signature
  } = event;

  const onScanExplorer = useCallback((explorerId) => {
    window.open(`https://explorer.vbchain.vn/mbc/tx/${explorerId}`, "_blank");
  }, []);

  return (
    <Box {...props}>
      <FormControl
        sx={{ mb: 1, width: "100%" }}
        variant="outlined"
        error={!!error?.agentBcAddress}
      >
        <InputLabel htmlFor="outlined-agent-bc-address" size="small">
          Agent blockchain address
        </InputLabel>
        <OutlinedInput
          id="outlined-agent-bc-address"
          type={"text"}
          value={agentBcAddress ?? '-'}
          label="Agent blockchain address"
          size="small"
          readOnly
        />
      </FormControl>
      <FormControl
        sx={{ mb: 1, width: "100%" }}
        variant="outlined"
        error={!!error?.userBcAddress}
      >
        <InputLabel htmlFor="outlined-user-bc-address" size="small">
          User blockchain address
        </InputLabel>
        <OutlinedInput
          id="outlined-user-bc-address"
          type={"text"}
          value={userBcAddress ?? '-'}
          label="User blockchain address"
          size="small"
          readOnly
        />
      </FormControl>
      <FormControl
        sx={{ mb: 1, width: "100%" }}
        variant="outlined"
        error={!!error?.id}
      >
        <InputLabel
          htmlFor="outlined-transaction-id-address"
          size="small"
        >
          Transaction Id
        </InputLabel>
        <OutlinedInput
          id="outlined-transaction-id"
          type={"text"}
          value={id ?? '-'}
          label="Transaction Id"
          size="small"
          readOnly
        />
      </FormControl>
      <FormControl
        sx={{ mb: 1, width: "100%" }}
        variant="outlined"
        error={!!error?.encryptedMessage}
      >
        <InputLabel
          htmlFor="outlined-encrypted-message-address"
          size="small"
        >
          Encrypted Message
        </InputLabel>
        <OutlinedInput
          id="outlined-encrypted-message"
          type={"text"}
          value={encryptedMessage ?? '-'}
          label="Encrypted Message"
          size="small"
          readOnly
        />
      </FormControl>
      {type === 'history' ? <FormControl
        sx={{ mb: 1, width: "100%" }}
        variant="outlined"
        error={!!error?.signature}
      >
        <InputLabel htmlFor="outlined-signature-address" size="small">
          Signature
        </InputLabel>
        <OutlinedInput
          id="outlined-signature"
          type={"text"}
          value={signature ?? '-'}
          label="Signature"
          size="small"
          readOnly
        />
      </FormControl> : null}
      <LoadingButton
        variant="outlined"
        size="small"
        disabled={!event.explorerId}
        onClick={() => onScanExplorer(event.explorerId)}
        endIcon={<Launch />}
      >
        Scan on Blockchain Explorer
      </LoadingButton>
    </Box>
  );
}

import {
  AccountCircle,
  Language,
  Logout,
  Mail,
  More,
  Notifications,
} from "@mui/icons-material";
import {
  AppBar,
  Badge,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

import { signOutAction } from "actions/user";
import { StorageKey } from "constants/storage";
import SquareAvatar from 'components/Common/Avatar';
import storage from "utils/storage";

function Navbar({ drawerWidth }) {
  const navigate = useNavigate();
  // State
  const avatarUrl = useSelector((state) => state.user.info.avatar);
  const agentName = useSelector((state) => state.user.info.kyc?.name);
  // Dispatch
  const dispatch = useDispatch();
  const dispatchSignOut = () => dispatch(signOutAction());

  // Hook
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const onSignOut = () => {
    dispatchSignOut();
    storage.removeItem(StorageKey.ACCESS_TOKEN);
    storage.removeItem(StorageKey.API_KEY);
    navigate('/auth/signin');
    handleMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleOpenProfile = () => {
    navigate('/profile');
  }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClick={handleMenuClose}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleOpenProfile}>
        <ListItemIcon>
          <AccountCircle fontSize="small" />
        </ListItemIcon>
        <ListItemText>Profile</ListItemText>
      </MenuItem>
      <MenuItem onClick={onSignOut}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        <ListItemText>Sign out</ListItemText>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" color="inherit">
          <Badge color="error">
            <Mail />
          </Badge>
        </IconButton>
        <p>Languages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          color="inherit"
        >
          <Badge color="error">
            <Notifications />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box
      position="fixed"
      sx={{
        // flexGrow: 1,
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        zIndex: 2,
      }}
    >
      <AppBar
        color="transparent"
        elevation={0}
        sx={{
          borderBottom: "solid rgb(0, 0, 0, 0.1) 1px",
          bgcolor: "#ffffff",
        }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              color="inherit"
              sx={{ marginRight: 1 }}
            >
              <Badge badgeContent={"EN"} color="info">
                <Language />
              </Badge>
            </IconButton>
            <IconButton
              size="small"
              aria-label="profile"
              color="inherit"
              onClick={handleProfileMenuOpen}
            >
              <SquareAvatar
                src={avatarUrl}
                name={agentName}
                alt={agentName}
                variant="circular"
                avatarSx={{ cursor: 'pointer' }}
                size={36}
              />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <More />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}

export default Navbar;

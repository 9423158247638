import { Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Collapse,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
  Divider,
} from "@mui/material";

import { useForm } from 'react-hook-form';
import { useCallback, useMemo, useState } from "react";
import IdentityResult, { IdentityResultSkeleton } from "./IdentityResult";
import LookupStatus, { LookupStatusSkeleton } from "components/Dashboard/Common/LookupStatus";
import { bcAddressRegex } from "constants/validation";
import LookupRepository from "repositories/lookup";
import PageHeader from "components/Common/Page/Header";
import PageContainer from "components/Common/Page/Container";

function IdentitySkeleton () {
  return (
    <Box mt={4} mb={6} width="100%" maxWidth={480}>
      <LookupStatusSkeleton />
      <IdentityResultSkeleton />
    </Box>
  )
}

export default function Identity() {

  const [userData, setUserData] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      userBcAddress: ''
    },
    mode: 'all'
  });

  const [toast, setToast] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const isUserFound = useMemo(() => (
    userData !== null &&
    typeof userData === 'object' &&
    Object.keys(userData).length > 0
  ), [userData]);

  const submitButtonDisabled = useMemo(() => !!errors.userBcAddress, [errors.userBcAddress]);

  const handleLookupIdentity = useCallback(async (data) => {
    setSubmitting(true);
    setToast(null);

    setUserData(null);

    try {
      const result = await LookupRepository.getIdentity(data.userBcAddress);
      setUserData(result);
    } catch (error) {
      setToast({
        severity: "error",
        description: error?.message || 'Cannot lookup identity.',
      });
    }
    setSubmitting(false);
  }, []);

  const renderForm = () => {
    return (
      <>
        <Box sx={{ mt: 2, mb: 1 }}>
          <Box display="flex" flexDirection="column" sx={{ mb: 1 }}>
            <Collapse in={toast !== null}>
              {toast && (
                <Alert severity={toast.severity} sx={{ mb: 1 }}>
                  {String(toast.description)}
                </Alert>
              )}
            </Collapse>
          </Box>

          <FormControl
            disabled={submitting}
            error={!!errors?.userBcAddress}
            sx={{ mb: 1, width: 480 }}
            variant="outlined"
            size="small"
          >
            <InputLabel htmlFor="outlined-agent-bc-address" size="small">
              User blockchain address
            </InputLabel>
            <OutlinedInput
              id="outlined-agent-bc-address"
              type={"text"}
              label="User blockchain address"
              {...register('userBcAddress', {
                validate: {
                  required: (value) => !!value || 'User address is required.',
                  bcAddress: (value) => !!value?.match(bcAddressRegex) || 'Invalid blockchain address.'
                }
              })}
            />
            <FormHelperText id="outlined-adornment-user-bc-address-text">
              {errors?.userBcAddress ? errors.userBcAddress.message : ""}
            </FormHelperText>
          </FormControl>
        </Box>

        <LoadingButton
          variant="contained"
          disabled={submitButtonDisabled}
          loading={submitting}
          onClick={handleSubmit(handleLookupIdentity)}
          endIcon={<Search />}
        >
          Lookup Identity
        </LoadingButton>
      </>
    )
  }

  const renderResult = () => {
    return (
      <Box
        display='flex'
        flexDirection="column"
        alignItems='center'
        mt={4}
      >
        <Divider flexItem/>
        {submitting && (<IdentitySkeleton />)}
        {!submitting && userData !== null && (
          <Box mt={4} mb={6} width="100%" maxWidth={480}>
            <LookupStatus
              status={isUserFound}
              title={isUserFound ? "User found" : "User not found."}
              description={isUserFound ? "Your registered user profile." : "Please try again."}
            />
            {isUserFound && <IdentityResult userData={userData} />}
          </Box>
        )}
      </Box>
    )
  }

  return (
    <>
      <PageContainer>
        <PageHeader title="Identity" />
      </PageContainer>

      <PageContainer scrollEnabled>
        <Typography variant="body1">Lookup the user's identity.</Typography>
        {renderForm()}
        {renderResult()}
      </PageContainer>
    </>
  );
}

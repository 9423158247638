import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";

import SquareAvatar from 'components/Common/Avatar';
import PageContainer from "components/Common/Page/Container";
import PageHeader from "components/Common/Page/Header";
import { StorageKey } from "constants/storage";
import { copyToClipboard } from 'utils/clipboard';
import storage from "utils/storage";

const SHOW_COPIED_TEXT_DURATION = 2000;

export default function Profile() {
  const userInfo = useSelector((state) => state.user.info);
  const agentName = userInfo.kyc?.name;
  const avatarUrl = userInfo.avatar;

  const apiKeyRef = useRef(storage.getJSON(StorageKey.API_KEY));

  const copiedTimeoutsRef = useRef({});
  const [copiedStates, setCopiedStates] = useState({});

  const handleCopy = useCallback((key, value) => {
    copyToClipboard(value);

    setCopiedStates((prevState) => ({ ...prevState, [key]: true }));
    clearTimeout(copiedTimeoutsRef.current[key]);

    copiedTimeoutsRef.current[key] = setTimeout(() => {
      setCopiedStates((prevState) => ({ ...prevState, [key]: false }));
    }, SHOW_COPIED_TEXT_DURATION);
  }, [])

  // const getUserInfo = useCallback(async () => {
  //   dispatchSetIndetermineProgressBar(true);
  //   const result = await dispatchGetUserInfo();
  //   if (!result.success) {
  //     setToast({
  //       severity: 'error',
  //       description: result.message || "Cannot get profile.",
  //     });
  //   }
  //   dispatchSetIndetermineProgressBar(false);
  // }, [dispatchGetUserInfo, dispatchSetIndetermineProgressBar]);

  // useEffect(() => {
  //   getUserInfo();
  // }, [getUserInfo]);

  const renderProfileForm = () => {
    return (
      <Box display="flex" flexDirection="column" sx={{ pb: 6 }}>
        {/* <Box sx={{ mb: 1 }}>
          <Collapse in={toast !== null}>
            {toast && (
              <Alert severity={toast.severity} sx={{ mb: 1 }}>
                {String(toast.description)}
              </Alert>
            )}
          </Collapse>
        </Box> */}

        <Box sx={{ mb: 4 }}>
          <SquareAvatar
            url={avatarUrl}
            name={agentName}
            alt={agentName}
            avatarSx={{ fontSize: 64 }}
            size={160}
          />
        </Box>

        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
          Account info
        </Typography>
        <FormControl
          sx={{ mb: 1, width: 480 }}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-username" size="small">
            Username
          </InputLabel>
          <OutlinedInput
            id="outlined-username"
            type={"text"}
            value={userInfo.account.username}
            label="Username"
            size="small"
            readOnly
          />
        </FormControl>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <FormControl
            sx={{ mb: 1, width: 480 }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-bc-address" size="small">
              Blockchain address
            </InputLabel>
            <OutlinedInput
              id="outlined-bc-address"
              type={"text"}
              value={userInfo.account.bcAddress}
              label="Blockchain address"
              size="small"
              readOnly
            />
          </FormControl>
          <Tooltip title={copiedStates.bcAddress ? "Copied" : "Copy Blockchain address"}>
            <IconButton
              sx={{ mb: 1 }}
              onClick={() => {
                handleCopy('bcAddress', userInfo.account.bcAddress)
              }}
            >
              {copiedStates.bcAddress ? <CheckIcon /> : <ContentCopyIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <FormControl
            sx={{ mb: 1, width: 480 }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-api-key" size="small">
              API key
            </InputLabel>
            <OutlinedInput
              id="outlined-api-key"
              type={"text"}
              value={apiKeyRef.current}
              label="API key"
              size="small"
              readOnly
            />
          </FormControl>
          <Tooltip title={copiedStates.apiKey ? "Copied" : "Copy API key"}>
            <IconButton
              sx={{ mb: 1 }}
              onClick={() => {
                handleCopy('apiKey', apiKeyRef.current);
              }}
            >
              {copiedStates.apiKey ? <CheckIcon /> : <ContentCopyIcon />}
            </IconButton>
          </Tooltip>
        </Box>

        <Typography variant="h6" gutterBottom sx={{ mb: 2, mt: 3 }}>
          KYC Info
        </Typography>
        <FormControl
          sx={{ mb: 1, width: 480 }}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-name" size="small">
            Name
          </InputLabel>
          <OutlinedInput
            id="outlined-name"
            type={"text"}
            value={userInfo.kyc.name}
            label="Name"
            size="small"
            readOnly
          />
        </FormControl>
        <FormControl
          sx={{ mb: 1, width: 480 }}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-description" size="small">
            Description
          </InputLabel>
          <OutlinedInput
            id="outlined-description"
            type={"text"}
            multiline
            rows={4}
            value={userInfo.kyc.description}
            label="Description"
            size="small"
            readOnly
          />
        </FormControl>
      </Box>
    )
  }

  return (
    <>
      <PageContainer>
        <PageHeader title="Profile" />
      </PageContainer>

      <PageContainer scrollEnabled>
        {renderProfileForm()}
      </PageContainer>
    </>
  );
}

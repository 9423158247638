import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import Auth from "components/Auth";
import ModalContainer from "components/Common/Modal/ModalContainer";
import ProgressBar from "components/Common/ProgressBar";
import Dashboard from "components/Dashboard";

function App() {
  return (
    <>
      <AppView />
      <ProgressBar />
      <ModalContainer />
      <Analytics mode={process.env.NODE_ENV} />
      <SpeedInsights />
    </>
  );
}

function AppView() {
  return (
    <Router>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Routes>
          <Route path="/auth/*" element={<Auth />} />
          <Route path="*" element={<Dashboard />} />
        </Routes>
      </QueryParamProvider>
    </Router>
  );
}

export default App;

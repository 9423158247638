import { Alert, Box, Collapse } from '@mui/material';
import { useMemo, useCallback, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLatest } from 'react-use';
import { getGeneralStatisticAction, getDailyStatisticAction } from 'actions/statistic';

import { setIndetermineProgressBarAction } from 'actions/general';
import PageContainer from "components/Common/Page/Container";
import PageHeader from "components/Common/Page/Header";
import { unwrapRequest } from 'utils/rest';

import { getLastDateRanges } from './utils';

import GeneralStatistic from './GeneralStatistic';
import TransactionStatistic from './TransactionStatistic';

const FETCH_STATISTIC_DATA_INTERVAL = 30000;

// const AnalyserTab = {
//   USER: 'user',
//   TRANSACTION: 'transaction',
// }

// const AnalyserTabName = {
//   [AnalyserTab.USER]: 'Users',
//   [AnalyserTab.TRANSACTION]: 'Transactions',
// }

function Analyser() {
  const dispatch = useDispatch();
  const dispatchGetGeneralStatisticAction = useCallback(() => dispatch(getGeneralStatisticAction()), [dispatch]);
  const dispatchGetDailyStatisticAction = useCallback((bcAddress, timeRanges) => dispatch(getDailyStatisticAction(bcAddress, timeRanges)), [dispatch]);
  const dispatchSetIndetermineProgressBar = useCallback((active) => dispatch(setIndetermineProgressBarAction(active)), [dispatch]);

  const general = useSelector((state) => state.statistic.general);
  const daily = useSelector((state) => state.statistic.daily);
  const bcAddress = useSelector((state) => state.user.info.account.bcAddress);

  // const [currentTab, setCurrentTab] = useQueryParam('tab', withDefault(StringParam, AnalyserTab.USER));
  const [toast, setToast] = useState(null);
  const fetchStatisticDataIntervalRef = useRef(null);
  const isLoadingRef = useRef(false);

  const timeRanges = useMemo(() => getLastDateRanges(30), []);

  const fetchStatisticData = useCallback(async ({ showProgressBar = true } = {}) => {
    if (isLoadingRef.current) {
      return;
    }
    isLoadingRef.current = true;
    if (showProgressBar) {
      dispatchSetIndetermineProgressBar(true);
    }

    try {
      await Promise.all([
        unwrapRequest(() => dispatchGetGeneralStatisticAction()),
        unwrapRequest(() => dispatchGetDailyStatisticAction(bcAddress, timeRanges)),
      ]);
      setToast(null);
    } catch (error) {
      setToast({
        severity: "error",
        description: error?.message || 'Cannot get statistic data.',
      });
    }

    isLoadingRef.current = false;
    if (showProgressBar) {
      dispatchSetIndetermineProgressBar(false);
    }
  },
  [
    isLoadingRef,
    dispatchSetIndetermineProgressBar,
    dispatchGetGeneralStatisticAction,
    dispatchGetDailyStatisticAction,
    bcAddress,
    timeRanges
  ]);

  const dailyChartData = useMemo(() => {
    if (!daily) {
      return null;
    }

    return daily.map((value, index) => ({
      day: timeRanges[index][0],
      value ,
    }));
  }, [daily, timeRanges]);

  useEffect(() => {
    fetchStatisticData();

    clearInterval(fetchStatisticDataIntervalRef.current);
    fetchStatisticDataIntervalRef.current = setInterval(() => {
      fetchStatisticData({ showProgressBar: false });
    }, FETCH_STATISTIC_DATA_INTERVAL);

    return () => {
      clearInterval(fetchStatisticDataIntervalRef.current);
    }
  }, [fetchStatisticData, isLoadingRef])

  // const renderTabLabel = (tab, value) => {
  //   return (
  //     <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 240, maxWidth: 240 }}>
  //       <Typography
  //         sx={{
  //           textAlign: 'left',
  //           textTransform: 'none',
  //           fontWeight: 500
  //         }}
  //       >
  //         {AnalyserTabName[tab]}
  //       </Typography>

  //       <Box>
  //         {!Number.isInteger(value) ? (
  //           <Skeleton
  //             variant="text"
  //             width={120}
  //             height={56}
  //             animation="wave"
  //           />
  //         ) : (
  //           <Typography
  //             variant="h3"
  //             component="div"
  //             sx={{
  //               fontWeight: "bold",
  //               textAlign: 'left',
  //             }}
  //           >
  //             {new Intl.NumberFormat().format(value)}
  //           </Typography>
  //         )}
  //       </Box>
  //     </Box>
  //   );
  // }

  // const renderTabs = () => {
  //   return (
  //     <Tabs
  //       value={currentTab}
  //       onChange={(_, tab) => {
  //         setCurrentTab(tab)
  //       }}
  //     >
  //       {[
  //         {
  //           tab: AnalyserTab.USER,
  //           value: general?.totalUsers,
  //         },
  //         {
  //           tab: AnalyserTab.TRANSACTION,
  //           value: general?.totalTransaction,
  //         }
  //       ].map(({ tab, value }) => (
  //         <Tab
  //           key={tab}
  //           label={renderTabLabel(tab, value)}
  //           value={tab}
  //         />
  //       ))}
  //     </Tabs>
  //   );
  // }

  // const renderTransactionChart = () => {
  //   return (
  //     <Box sx={{ mt: 4 }}>
  //       <Card
  //         variant="outlined"
  //         sx={{
  //           p: 3,
  //           borderRadius: 2,
  //         }}
  //       >
  //         <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
  //           Transactions
  //         </Typography>
  //         <TransactionChart data={dailyChartData} />
  //       </Card>
  //     </Box>
  //   );
  // }

  return (
    <>
      <PageContainer>
        <PageHeader title="Analyser" />
      </PageContainer>

      <PageContainer scrollEnabled sx={{ overflowX: 'hidden' }}>
        <Box display="flex" flexDirection="column" sx={{ mb: 1 }}>
          <Collapse in={toast !== null}>
            {toast && (
              <Alert severity={toast.severity} sx={{ mb: 1 }}>
                {String(toast.description)}
              </Alert>
            )}
          </Collapse>
        </Box>
        <GeneralStatistic
          data={general}
          isLoading={!general}
          sx={{ maxWidth: 720 }}
        />
        <TransactionStatistic
          data={dailyChartData}
          isLoading={!daily}
          sx={{ mt: 2, pb: 6 }}
        />
      </PageContainer>
    </>
  );
}

export default Analyser;

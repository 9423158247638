import { Box, Card, Skeleton, Typography } from '@mui/material';

import TransactionChart from './TransactionChart';

export default function TransactionStatistic({ data, sx, isLoading }) {
  const renderChart = () => {
    if (isLoading) {
      return (
        <Skeleton
          height={400}
          variant="rounded"
          animation="wave"
        />
      );;
    }

    return (
      <Box sx={{ height: 400 }}>
        <TransactionChart data={data} />
      </Box>
    );
  }

  return (
    <Box sx={{ ...sx }}>
      <Card
        variant="outlined"
        sx={{
          p: 3,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
          Transactions
        </Typography>
        {renderChart()}
      </Card>
    </Box>
  )
}

import React from 'react'
import { Box } from '@mui/material'

export default function PageLayout({ children, ...props }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      {...props}
    >
      {children}
    </Box>
  )
}

import { IdentityAction } from "constants/redux";

const initialState = {
  info: null,
  formData: null,
};

const identityReducer = (state = initialState, action) => {
  switch (action.type) {
    case IdentityAction.GET_IDENTITY_SUCCESS: {
      return {
        ...state,
        info: action.data,
      };
    }
    case IdentityAction.GET_IDENTITY_FAILED: {
      return {
        ...state,
        info: null
      };
    }
    default:
      return state;
  }
};

export default identityReducer;

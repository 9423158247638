import {
  Box,
  LinearProgress,
} from "@mui/material";
import { useSelector } from 'react-redux';

export default function ProgressBar() {
  const progressBar = useSelector((state) => state.general.progressBar);

  return (
    <Box
      sx={{
        width: "100%",
        position: "absolute",
        top: 0,
        display: progressBar ? "inline" : "none",
        zIndex: 40,
      }}
    >
      <LinearProgress />
    </Box>
  )
}
